import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <div className="content">
        <iframe
          src="https://netflix-comic-con-mde.checkfront.com/reserve/"
          width="100%"
          height="100%"
          className="frame"
        ></iframe>
      </div>
    </div>
  );
}

export default App;
